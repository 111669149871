import React, { Component } from 'react';
import { Label, Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { withCookies, Cookies } from 'react-cookie';
import Auth from './Auth.js'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      word: "",
      error: "",
    }
  }

  componentDidMount() {
    if (this.props.cookies.get('jwt')) {
      this.auth(this.props.cookies.get('jwt'));
    }
    this.nameInput.focus(); 
  }

  auth(token) {
    fetch('https://' + window.location.hostname + ':49001/token', {      
      method: 'get',      
      headers: {
        'Authorization': 'Bearer ' + token
      },
    })
    .then(res => res.json())
    .then((result) => {
      if (result.error) {
        this.setState({error: result.error});
      } else {
        Auth.auth.token = token;
        this.props.onSuccess();
      }
    }
    )
  }

  login = (e) => {
    e.preventDefault();
    fetch('https://' + window.location.hostname + ':49001/login', {      
      method: 'post',
      body: JSON.stringify({word: this.state.word}),
      headers: {
        'Content-Type': "application/json; charset=utf-8",
        'Accept': 'application/json',
      },
    })
    .then(res => res.json())
    .then((result) => {
      if (result.token) {
        var future = new Date();
        future.setDate(future.getDate() + 30);

        this.props.cookies.set('jwt', result.token, { expires: future });
        Auth.auth.token = result.token;
        this.props.onSuccess();
      } else {
        this.setState({
          error: result.error
        })
      }
      },
    )
  }

  setWord(e) {
    this.setState({word: e.target.value});
  }

  render() {
    return (
      <div className="animated fadeIn">
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col md="4">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form onSubmit={this.login}>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" autoComplete="current-password" innerRef={(input) => { this.nameInput = input; }} required value={this.state.word} onChange={this.setWord.bind(this)}/>
                      </InputGroup>
                        { this.state.error ?  
                      <Row> 
                        <Col xs="2">                     
                          <i className="fa fa-warning fa-lg"></i>
                        </Col>
                        <Col xs="6">
                          <p>{this.state.error}</p>
                        </Col>
                      </Row>
                        : null }
                      <Row>
                        <Col xs="6">
                          <Button type="submit" color="primary" className="px-4" onClick={this.login}>Login</Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
      </div>
    );
  }
}

export default withCookies(Login);
